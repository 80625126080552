// Dependencies
import {call, put, select} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

// Types
import {EditUserAction} from '../types';
import {EditUserResponse} from '@services/auth/types';

// Services
import * as authServices from '@services/auth';

// Selectors
import {selectAuthUser} from '../selectors';

// Actions
import {closeModal} from '@store/ui/modal/actions';
import {setAuthUser} from '../actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';

function * editUserProcess({payload: {name}}: EditUserAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_LOADING_EDIT_USER, true));

    const response: EditUserResponse = yield call(authServices.editUser, name);
    if (response) {
      const currentUser = yield select(selectAuthUser);

      yield put(setAuthUser({
        ...currentUser,
        user: {
          ...currentUser,
          name
        }
      }));

      yield put(closeModal());
    }
    yield put(setIsLoading(LoadersId.IS_LOADING_EDIT_USER, false));
  } catch (error) {
    yield put(setIsLoading(LoadersId.IS_LOADING_EDIT_USER, false));
  }
}

export default editUserProcess;
